
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Row, Col, Container, Button, Image, Badge, Nav, Modal, Spinner } from 'react-bootstrap';

import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import Icofont from 'react-icofont';
import ExtraItems from './custom/ExtraItems';
import CartItems from './custom/CartItems';
import NewCheckout from './custom/NewCheckout';
import CityUtilityMeter from './common/CityUtilityMeter'
import { CheckoutCart } from './CheckoutCart'
import axios from 'axios'
import moment from 'moment';
import { Helmet } from 'react-helmet';


import { AppContext } from '../context.js'
import useFetch from '../useFetch.js'
import useFetchARefresh from '../useFetchAutoRefresh'
import { useHistory } from "react-router-dom";


import DetailFoodOffers from './custom/DetailFoodOffers';
import DetailGallery from './custom/DetailGallery';
import DetailRestaurantInfo from './custom/DetailRestaurantInfo';
import DetailBookTable from './custom/DetailBookTable';
import DetailRatingAndReviews from './custom/DetailRatingAndReviews';

import styled, { keyframes } from "styled-components";
import { FadeIn } from 'react-animations'
import { floor } from 'lodash';
import ScrollButton from './custom/ScrollButton';
import ReturnButton from './custom/ReturnButton'
import useWindowSize from '../useWindowsize';
import { Device } from '@capacitor/device';

const FadeInAnimation = keyframes`${FadeIn}`;
const FadeInDiv = styled.div`
  animation: infinite 5s ${FadeInAnimation};
`;

const Detail = (props, context) => {

	const { state, dispatch } = useContext(AppContext)
	let history = useHistory()

	const [showAddressModal, setShowAddressModal] = useState(false);

	const [addedItem, setaddedItem] = useState(null)
	const [show, setShow] = useState(false);
	const hideAddressModal = () => setShowAddressModal({ showAddressModal: false });
	const [showRestaurantClosed, setShowRestaurantClosed] = useState(true)


	let { restaurant, id } = useParams();

	const [info, setInfo] = useState(null)
	const { data: infoData, loading: loadingInfo, error: errorInfo } = useFetch('/api/restaurants/' + restaurant)
	useEffect(() => {
		console.log("infodata:", infoData)

		//setInfo(infoData);
		console.log("check values", info, state.location?.polygonRestaurants);
		if (infoData) {
			setIsFav(infoData.isFav)

			if (state.location?.polygonRestaurants) {


				let rest_found = state.location.polygonRestaurants.find(r => r.restaurace_id == infoData.id)

				if (rest_found) {

					setInfo({
						...infoData,
						delivery_price: Number(infoData.delivery_price) + Number(rest_found.doprava),
						delivery_tmin: infoData.delivery_tmin + Number(rest_found.cas),
						delivery_tmax: infoData.delivery_tmax + Number(rest_found.cas),
						minimal_order: infoData.minimal_order + Number(rest_found.minimalni),
						isFreeDelivery: rest_found.nofreedelivery == "a" ? 1 : 0

					})
					//console.log("OVERRIDE:",{...infoData,delivery_price:1000})
					//setInfo({},...info)
				} else {
					setInfo(infoData)
				}

			} else {
				setInfo(infoData)
			}
		}

		//if(info.rest_id in state.location.polygonRestaurants) { přepsat info data }
		//Pokud je restaurace upravena vlastním polygonem, přepsat data



	}, [infoData])


	const { data: cityUtility, loading: loadingcityUtility, error: errorcityUtility } = useFetchARefresh('/api/menu/cityUtility/' + id)
	const { data: menu, loading } = useFetch('/api/menu/' + restaurant)

	const [deviceInfo, setDeviceInfo] = useState()

	useEffect(() => { //Vrátí okno nahoru po načtení menu
		window.scrollTo(0, 0)
		getDeviceInfo(setDeviceInfo)
	}, [])

	const isOpen = useMemo(() => {
		if (info && !loadingInfo && !errorInfo) {
			if (cityUtility?.cityUtility == 100) { return false }
			if (info.delivery_from == null || info.delivery_to == null) { return false }

			let arFrom = info?.delivery_from.split(":")
			let arTo = info?.delivery_to.split(":")

			let currentTime = new Date();
			//currentTime.setHours(13,0);
			let delivery_from = new Date();
			delivery_from.setHours(arFrom[0], arFrom[1]);
			let delivery_to = new Date();
			delivery_to.setHours(arTo[0], arTo[1]);

			//console.log("computing isOpen")
			if (!(currentTime > delivery_from && currentTime < delivery_to)) {
				console.log("Zavřeno")
				return false
			}
			//console.log("open with data")
			return true
		}
		//console.log("open - no data")
		return true
	}, [info, cityUtility])


	const handleClose = () => setShow(false);
	const displayZoomedImage = false;
	const displayExtraItems = (item) => {
		setaddedItem(item)
		setShow(true)
	};

	const size = useWindowSize();

	const [isInCheckout, setIsInCheckout] = useState(false)
	const [checkoutInfo, setCheckoutInfo] = useState({})


	const [orderSubmiting, setOrderSubmiting] = useState(false)
	const [orderError, setOrderError] = useState(false)
	const [finishGopayAlert, setFinishGopayAlert] = useState(false)

	const onSubmit = data => { return { isValid: data } };

	const submitNewOrder = async (e) => {

		// console.log("Submited with checkoutInfo: ",checkoutInfo)
		// return

		let isValid
		if (!state.user || !(state.user.telefon && state.user.login && state.user.jmeno)) {
			await checkoutInfo.handleSubmit(data => isValid = data)()
			if (!isValid) return
		}
		else {
			isValid = state.user.telefon && state.user.login
		}

		if (!isOpen && !checkoutInfo.deliveryTime) {
			alert("Vyberte čas doručení")
			return
		}

		if (checkoutInfo.deliveryTime == "Invalid date") {
			alert("Neplatné datum")
			return
		}

		if (!checkoutInfo.deliveryTime && info.delivery_to?.split(":")[0] < Number(moment().format('k'))) {
			alert("Restaurace právě zavřela. Dnes bylo otevřeno do: " + info.delivery_to)
			return;
		}

		//e.preventDefault()

		if (isValid && state.location) {

			setOrderSubmiting(true)

			const data = {
				cart: state.cart,
				user: checkoutInfo.user,
				restaurant: state.activeRestaurant.id,
				noteToCourier: checkoutInfo.noteToCourier,
				noteToChef: checkoutInfo.noteToChef,
				deliveryTime: checkoutInfo.deliveryTime,
				location: state.location.adresa.kod_adm,
				platform: window.Capacitor?.platform || "web",
				deviceVersion: deviceInfo || "",
				notification_token: state.notification_token,
				marketingAgreement: checkoutInfo.marketingAgreement,
				payment: {
					method: checkoutInfo.paymentMethod,
					creditsUsed: state.totalAddition.creditsUsed,
					totalCheck: state.total.totalPrice,
					minimalOrderAddition: state.total.minimalOrderAddition,
					tipCourier: state.totalAddition.tipCourier,
					isLunch: state.isLunch,
					deliveryPrice: state.total.deliveryPrice,
					packaging: state.total.packaging + (state.activeRestaurant.delivery_orderpackaging || 0)
				},
				context_log: JSON.stringify(state)
			}

			if (checkoutInfo.paymentMethod == "twisto") {
				let transaction = await getTwistoTransaction(data)

				if (transaction.status == "accepted") {
					data.payment.twisto_transaction_id = transaction.transaction_id
					console.log(data)
				} else {
					setOrderSubmiting(false)
					setOrderError({ status: 'Twisto zamítnuto', message: "Twisto zamítnuto ERROR #228" })
					return
				}

				console.log(transaction)

			}

			axios({
				method: 'post',
				url: '/api/orders',
				data
			}).then(response => {
				console.log(response)
				//dispatch({ type: 'CONVERSION', payload: { order_number: response.data.order_number, data } })
				if (response.data.status == "ok") { 
					//Objednávka dokončena
					dispatch({ type: 'ORDERFINISHED' })
					setTimeout(() => {
						history.push("/hotovo/" + response.data.order_number)
					}, 150); // Adjust delay as needed
				} else if (response.data.redirect) {
					//Přesměrování GOPAY
					if (state.platform != "web") {
						setOrderSubmiting(false)
						setFinishGopayAlert(true)
					}
					window.location.replace(response.data.redirect)
				} else {
					//SERVER ODPOVĚDĚL NOT OKAY
					setOrderSubmiting(false)

					// Send a POST request with the error
					let errorLogContent = {
						type:"client submitneworder failed response (line 260)",
						url: window.location.href,  // Include the page URL where the error occurred
						userAgent: navigator?.userAgent || "unknown",  // Include browser and OS details
						platform: window.Capacitor?.platform || "unknown",
						error:response.data
					}

					fetch('/api/orders/error', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(errorLogContent)
					})
					
					if (response.data.status === "error") {
						if (typeof response.data.message === "string") {
							// If message is a string, use it directly
							setOrderError({ 
								status: 'error', 
								message: response.data.message 
							});
						} else if (Array.isArray(response.data.message) && response.data.message.length > 0) {
							// If message is an array, use the error_desc from the first item
							setOrderError({ 
								status: 'error', 
								message: response.data.message[0].error_desc 
							});
						} else {
							// Fallback message in case of other structures
							setOrderError({ 
								status: 'error', 
								message: "Chyba při zpracování objednávky. Nepodařilo se zpracovat objednávku na serveru. ERROR #292" 
							});
						}
					} else {
						// Handle unknown status errors
						setOrderError({ 
							status: 'error', 
							message: "Chyba při zpracování objednávky. Nepodařilo se zpracovat objednávku na serveru. ERROR #299" 
						});
					}
					

					
				}

			}).catch(error => {
				console.log(error)
				setOrderSubmiting(false)

				// Send a POST request with the error
				// Extract relevant error information
				const serializedError = {
					message: error.message,
					name: error.name,
					stack: error.stack,
					config: error.config,
					code: error.code,
					response: error.response ? {
						status: error.response.status,
						data: error.response.data,
						headers: error.response.headers,
					} : undefined,
					request: error.request ? {
						// Include necessary request properties if needed
					} : undefined
				};
			
				let errorLogContent = {
					type: "client Catch failed request clause (line 288)",
					url: window.location.href,  // Include the page URL where the error occurred
					userAgent: navigator?.userAgent || "unknown",  // Include browser and OS details
					platform: window.Capacitor?.platform || "unknown",
					state,
					error: serializedError
				};

				fetch('/api/orders/error', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(errorLogContent)
				})


				//setOrderError({ status: 'unknown', message: [{ error_code: null, error_type: null, error_desc: "Nepodařilo se navázat spojení se serverem, zkontrolujte připojení. Chyba: " + JSON.stringify(error) }] })

				if (error.response) {
				// Ensure error.response.data.message is a string
				const message = typeof error.response.data.message === 'string'
					? error.response.data.message
					: 'An unexpected error occurred. Please try again later.'
				setOrderError({ status: error.response.data.status, message })
				} else if (error.request) {
				setOrderError({ status: 'unknown', message: "Server did not respond. Please check your internet connection. ERROR #314" })
				} else {
				setOrderError({ status: 'unknown', message: "An error occurred while sending the order. Server did not respond. ERROR #317" })
				}


			})
		} else {
			alert("Neplatné informace o objednávce")
		}

	}



	let totalPrice = state.total.totalPrice || 0
	let obal = state.total.packaging
	let doprava = Number(info?.delivery_price) + Number(info?.vlastni == "a" ? 0 : Number(state?.location?.doprava || 0))

	let minimal_order = (state?.activeRestaurant?.minimal_order || 0) < (state?.location?.minimalni || 0) ? (state?.location?.minimalni || 0) : (state?.activeRestaurant?.minimal_order || 0)

	//Oblíbené
	const [isFav, setIsFav] = useState(false)

	useEffect(() => {
		if (info)
			setIsFav(info.isFav)
	}, [info])

	const addToFav = (param) => {

		axios({
			method: 'post',
			url: (process?.env?.ROOT_URL || "") + '/api/user/fav_rest/' + param,
		}).then(response => {
			if (!response.data.error)
				console.log("Ok")
			else
				console.log("Error")
		})
	}

	const removeFav = (param) => {

		axios({
			method: 'delete',
			url: (process?.env?.ROOT_URL || "") + '/api/user/fav_rest/' + param,
		}).then(response => {
			if (!response.data.error)
				console.log("Ok")
			else
				console.log("Error")
		})
	}

	if (errorInfo || (info && info.status == "error")) return (<Redirect to="/nenalezeno"></Redirect>)

	if (!info) return (
		<Container className="pb-5" style={{ "paddingTop": "8rem", "minHeight": "60vh" }}>
			<Row>
				<Col md={12} className="text-center load-more">
					<Button variant="primary" type="button" disabled="">
						<Spinner animation="grow" size="sm" className='mr-1' />
						Načítání...
					</Button>
				</Col>
			</Row>
		</Container>
	)

	return (
		<>
			{/* <FadeInDiv>
				<div className="order-absolute-cart-mobile"></div>
			</FadeInDiv> */}
			{size.width <= 767.98 ?
				<>
					{/* {<Button className="order-display-cart-mobile-button">Zobrazit košík</Button>} */}
					{/* {state.cart.length > 0 && totalPrice > minimal_order ? !isInCheckout ? <NavLink className="order-display-cart-mobile-button " style={{ "background": "rgb(198, 39, 39)", "color": "white" }} exact to={"/rozvoz-jidel/" + state.activeRestaurant.mesto_url + "/" + state.activeRestaurant.rest_url + "/" + "platba"} >{isOpen ? "Objednat" : "Objednat na později"}</NavLink> : "" : ""} */}  {/* <Button onClick={submitNewOrder} className="order-display-cart-mobile-button">Dokončit objednávku</Button>  */}
					{!isInCheckout &&
						(state.cart.length ?
							<NavLink className="order-display-cart-mobile-button " style={{ "background": "rgb(251,186,0)", "color": "white" }} exact to={"/rozvoz-jidel/" + state.activeRestaurant.mesto_url + "/" + state.activeRestaurant.rest_url + "/" + "platba"} ><Icofont icon="cart" /> Košík <small>{totalPrice} Kč</small> </NavLink>
							:
							<Link className="order-display-cart-mobile-button " style={{ "backgroundColor": "rgb(251,186,0)", "color": "white" }} ><Icofont icon="cart" /> Košík <small>prázdný</small> </Link>
						)}
				</>
				: ""
			}

			<Helmet>
				<title>Rozvoz jídla{info ? ", " + info.name + " " + info.city : ""} - Jidlopodnos</title>
			</Helmet>
			<ScrollButton />
			{!isInCheckout &&
				<ReturnButton />
			}

			<Modal className="extra-items" show={!isOpen && showRestaurantClosed}>
				<Modal.Body>
					<Button className="extra-items-close-button" onClick={() => { setShowRestaurantClosed(false) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>

					<h5 className="extra-items-title">Upozornění</h5>
					<hr></hr>
					<p className="">{cityUtility?.cityUtility == 100 ? "Všichni řidiči momentálně vyřizují jiné objednávky. Objednat si můžete pouze na později." : "Provozovna momentálně nerozváží. Objednávky z této provozovny jsou možné pouze na pozdější dobu."}</p>

				</Modal.Body>
			</Modal>

			{/* Modal: probíhá přesměrování */}
			<Modal className="extra-items" show={orderSubmiting}>
				<Modal.Body>

					{/* <Button className="extra-items-close-button" onClick={() => { setShowRestaurantClosed(false) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button> */}

					<h5 className="extra-items-title">Objednávka se odesílá</h5>
					<hr></hr>
					<p className="">Zpracováváme vaší objednávku. Do pár vteřin, byste měli být přesměrování.</p>

				</Modal.Body>
			</Modal>
			{/* Modal: Chyba */}
			<Modal className="extra-items" show={orderError}>
				<Modal.Body>

					<Button className="extra-items-close-button" onClick={() => { setOrderError(false) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>

					<h5 className="extra-items-title">Chyba</h5>
					<br></br>
					<p>{orderError && typeof orderError.message === 'string' ? orderError.message : "Pokuste se stránku obnovit a objednat znova. ERROR #467"}</p>
					<hr></hr>
					<p className="">Chyba při vytváření objednávky. <br></br><small></small></p>

				</Modal.Body>
			</Modal>

			{/* Modal: dokončete platbu */}
			<Modal className="extra-items" show={finishGopayAlert}>
				<Modal.Body>

					<Button className="extra-items-close-button" onClick={() => { setFinishGopayAlert(false) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>

					<h5 className="extra-items-title">Objednávka odeslána ke zpracování</h5>
					<br></br>

					<hr></hr>
					<p className="">Dokončete objednávku prostřednictvím platební brány</p>

					<Link to="/"><Button onClick={() => dispatch({ type: 'ORDERFINISHED' })} variant='primary'>Hotovo</Button></Link>

				</Modal.Body>
			</Modal>

			<ExtraItems show={show} addedItem={addedItem} onClose={handleClose} item={props.item} />
			<Row style={{ marginRight: "0", "background": "white" }}>
				<Col className="pr-0" md={8}>
					{/* Left menu */}
					<div>
						<section className={`restaurant-detailed-banner ${isInCheckout && size.width < 767.98 && "d-none"}`} style={{ "marginTop": size.width < 1200 ? "80px" : "auto" }}>
							{!loadingInfo && info.id &&
								<div className="text-center">
									<Image fluid className={`cover `} src={`https://www.jidlopodnos.cz/data/restaurace/mkarta/${info.id}.jpg`} onError={(e) => e.target.src="https://old.jidlopodnos.cz/images/1/new_slide2_2.jpg"} /> {/* https://old.jidlopodnos.cz/images/1/new_slide2_2.jpg */}
								</div>
							}
							<div className="restaurant-detailed-header">
								<Container>
									<Row className="d-flex align-items-end">
										<Col className="offset-md-3" md={7}>

											{size.width >= 767.98 &&
												<Image fluid className="mr-3 float-left" alt="" src={info ? `https://www.jidlopodnos.cz/data/restaurace/logo/${info.id}.jpg` : ""} />
											}{/*onError={(e)=>{e.target.onerror = null;e.target.style.display="none" }} /e.target.src="/resources/logo.svg" */}


											<h2 className="text-white">{info ? info.name : ""}</h2>

											{/* Delivery Time: */}
											<Button style={{ backgroundColor: "#c62727" }} type="button"> {info ? <><Icofont icon="clock-time" />{info.delivery_tmin + (Number(state?.location?.cas || 0))} - {info.delivery_tmax + (Number(state?.location?.cas || 0))}min</> : <><Spinner animation="grow" size="sm" className='mr-1' />Načítání...</>}
											</Button>

											<div style={{ "overflow": "auto" }}>

												{info && info.delivery_from && info.delivery_to && <>
													<br></br>
													<span className="text-white info-text">Dnes otevřeno: {info?.delivery_from?.slice(0, 5)} - {info?.delivery_to?.slice(0, 5)}</span></>
												}
												<br></br>
												<span className="text-white info-text">Místo pro rozvoz: {info && info.city}</span>
												<br></br>
												<span className="text-white">Minimální cena objednávky: {state?.location ? "":"od "} {info && (info.minimal_order > minimal_order ? info.minimal_order : minimal_order)} Kč</span>
												<br></br>
												<span className="text-white">Cena rozvozu: {state?.location ? "":"od "} {doprava} Kč</span>
												<br></br>
												{info && info.isFreeDelivery == 1 && state.location?.nofreedelivery !== "a" && <><span className="text-white">Rozvoz zdarma od: {info.delivery_free} Kč</span> <br></br></>}

												{info && info.isBonus == 1 && <> <span className="text-white">Bonusový program: vrátíme vám {floor(info.percentage_bonus)}%</span> <br></br></>}

												<span className="text-white">Platba kartou: {info && info.Cashless == 0 ? "Ne" : "Ano"}</span>
												<br></br>
												<span className="text-white">Platba stravenkami: {info && info.isVoucher == 0 ? "Ne" : "Ano"}</span>
											</div>

										</Col>
										<Col className="" style={{ "color": "white", "display": "flex", "flexDirection": "column", "alignItems": "center" }} md={2}>
											<CityUtilityMeter cityCapacity={cityUtility} />

											<h6 className="pt-2" style={{ "color": "white", "text-align": "center" }}>Vytíženost kurýrů</h6>
										</Col>
									</Row>
								</Container>
							</div>

						</section>


						<section className={`offer-dedicated-nav bg-white shadow-sm  ${isInCheckout && size.width < 767.98 && "d-none"}`}>

							<div>
								<Col className="left-padding" md={12}>
									<span className="restaurant-detailed-action-btn float-right">
										{state.user ? isFav ?
											<Button onClick={() => { removeFav(info.id); setIsFav(0) }} variant='light' size='sm' className="border-light-btn mr-1" type="button"><Icofont icon="close-circled" className='text-danger' /> Odstranit z oblíbených</Button>
											:
											<Button onClick={() => { addToFav(info.id); setIsFav(1) }} variant='light' size='sm' className="border-light-btn mr-1" type="button"><Icofont icon="heart" className='text-danger' /> Označit jako oblíbené</Button>
											: ""}


									</span>
									<Nav id="pills-tab">
										<Nav.Item>
											<NavLink className="nav-link" exact to="objednat">Objednat online</NavLink>
										</Nav.Item>
										{/* <Nav.Item>
										<NavLink className="nav-link"  to={`galerie`} >Galerie </NavLink>
									</Nav.Item> */}
										<Nav.Item>
											<NavLink className="nav-link" exact to="info">Informace o restauraci</NavLink>
										</Nav.Item>
										{/* <Nav.Item>
										<NavLink className="nav-link" exact to="rezervovat-stul">Zarezervovat stůl</NavLink>
									</Nav.Item> */}
										<Nav.Item>
											<NavLink className="nav-link" exact to="hodnoceni">Hodnocení a Recenze</NavLink>
										</Nav.Item>
									</Nav>
								</Col>
							</div>

						</section>
						{isInCheckout && size.width < 767.98 ?
							<>
								<div className="mt-3"></div>
								<CheckoutCart isInCheckout={isInCheckout} minimal_order={minimal_order} info={info} isOpen={isOpen} submitNewOrder={submitNewOrder} hideFinishButton={true} />
							</>
							: ""}
						{displayZoomedImage ?
							<div className="offer-dedicated-body-absolute-image-parent">
								<div className="offer-dedicated-body-absolute-image">

								</div>
							</div> : ""
						}

						<section className={`offer-dedicated-body pt-4 pb-2mb-4 container-fluid ${size.width >= 767.98 && "pr-0"} left-padding`}>
							<div className="offer-dedicated-body" >
								<div className="offer-dedicated-body-left">
									<Switch>
										{/* <Route path="/:id/:restaurant/objednat" exact component={DetailFoodOffers} /> */}
										<Route path="/rozvoz-jidel/:id/:restaurant/objednat" render={(props) => <DetailFoodOffers restaurant={info} error={errorInfo} menu={menu} loadingMenu={loading} {...props} />} />
										<Route path="/rozvoz-jidel/:id/:restaurant/galerie" exact component={DetailGallery} />
										<Route path="/rozvoz-jidel/:id/:restaurant/info" render={(props) => <DetailRestaurantInfo info={info} {...props} />} />
										<Route path="/rozvoz-jidel/:id/:restaurant/rezervovat-stul" exact component={DetailBookTable} />
										<Route path="/rozvoz-jidel/:id/:restaurant/hodnoceni" exact component={DetailRatingAndReviews} />
										<Route path="/rozvoz-jidel/:id/:restaurant/platba" render={(props) => <NewCheckout submitNewOrder={submitNewOrder} totalPrice={totalPrice - doprava} orderSubmiting={orderSubmiting} isOpen={isOpen} restaurant={restaurant} cityUtility={cityUtility?.cityUtility} setCheckoutInfo={setCheckoutInfo} isInCheckout={isInCheckout} setIsInCheckout={setIsInCheckout} {...props} />} />
										<Route path="/rozvoz-jidel/:id/:restaurant" render={(props) => <Redirect to='objednat'></Redirect>} />
									</Switch>
								</div>
							</div>
						</section>
					</div>
					{/* /End left menu */}
				</Col>

				<Col md={4} style={{ "marginTop": "80px", "marginBottom": "24px" }}>
					{size.width > 767.98 ?
						<CheckoutCart isInCheckout={isInCheckout} minimal_order={minimal_order} info={info} isOpen={isOpen} submitNewOrder={submitNewOrder} />
						: ""}
				</Col>

			</Row>
		</>
	);

}

//Získat validaci z Twisto skz naše api
const getTwistoTransaction = async order => {

	let options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(order)
	}

	const result = await fetch("/api/payment/twisto/payload", options)

	if (!result.ok) {
		console.log("Chyba Twisto") //Doplnit error
	}

	const twistoResult = await result.json()

	if (!twistoResult.status == "OK" || !twistoResult.payload) {
		console.log("Chyba Twisto, not OK") //Doplnit error
	}

	let { payload } = twistoResult

	let checkResult = await twistoCheck(payload)

	return checkResult

}

const twistoCheck = async payload => {
	return new Promise((resolve, reject) => {
		window.Twisto.check(payload,
			function (response) {
				console.log("Twisto check response", response)
				resolve(response)
			}, function (e) {
				console.log("chyba: ", e)
				resolve(null)
			})
	})
}

const getDeviceInfo = async (setDeviceInfo) => {
	try {
		let di = await Device.getInfo();
		setDeviceInfo(di)
		return
	} catch (error) {
		return false
	}
}


export default Detail;